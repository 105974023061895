@import "../base/base";

.advert {
  &__title {
    font-size: 1.5rem;
    margin-bottom: 0;
    margin-top: $spacer;
  }

  &__pay-button-holder {
    margin-top: $spacer * 1.5;
    display: flex;
    justify-content: center;
  }

  &__pay-button {
    --bs-btn-font-size: 16px;
    --bs-btn-font-weight: #{$font-weight-bold};
    --bs-btn-color: #{$danger};
    --bs-btn-hover-color: #{shade-color($danger, $btn-unstyled-color-hover-amount)};
    --bs-btn-active-color: #{shade-color($danger, $btn-unstyled-color-active-amount)};
  }
}

.advert-description {
  margin-top: $spacer * 1.5;

  &__content {
    > *:last-child {
      margin-bottom: 0;
    }

    &_overflow {
      position: relative;
      max-height: 205px;
      overflow: hidden;

      &::after {
        content: "";
        height: 3rem;
        background-image: linear-gradient(to bottom, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 10000%) 100%);
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
      }
    }
  }
}
