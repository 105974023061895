@import "../base/base";

.header {
  height: 52px;
  background-color: $color-brown-dark;
  display: flex;
  align-items: center;
  width: 100%;
  top: 0;
  transition: top 0.1s linear;
  position: sticky;
  z-index: $zindex-sticky;

  &_sticky {
    top: -52px;
  }

  &__logo {
    color: $white;
    text-decoration: none;
    font-size: $font-size-lg;    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: $font-weight-bold;

    &:hover {
      color: $gray-300;
    }
  }
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}
