@import "../base/base";

.post-card {
  overflow: hidden;
  position: relative;

  &__title {
    font-weight: 500;
    color: $body-color;
    border: none;
    background: transparent;
    text-align: left;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 1rem;
    line-height: normal;
  }

  &__preview-holder {
    position: relative;
  }
}
