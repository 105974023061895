@use 'sass:map';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';

$custom-theme-colors: ();
$theme-colors: map.merge($theme-colors, $custom-theme-colors);

@import '~bootstrap/scss/maps';

$font-size-root: 14px;
$color-brown-dark: #272727;
$color-gray-light: #f4f4f4;
$enable-grid-classes: true;
$enable-cssgrid: true;
$enable-negative-margins: true;
$utilities-bg-colors: map.merge(
  $utilities-bg-colors,
  (
    "brown-dark": $color-brown-dark,
  )
);
$zindex-offcanvas-backdrop: 1032;
$zindex-offcanvas: 1032;
$btn-unstyled-color-hover-amount: 30% !default;
$btn-unstyled-color-active-amount: 60% !default;
