@import '../base/base';

.post-list {
  --bs-gap: 3rem;

  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem 1.5rem;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1.5rem;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(4, 1fr);
  }

  &_sm {
    @include media-breakpoint-between(md, lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr;
    }
  }
}
