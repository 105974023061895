@import "../base/base";

.avatar {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid $white;
  position: relative;

  &_sm {
    width: 60px;
    height: 60px;
  }

  &_xs {
    width: 50px;
    height: 50px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
