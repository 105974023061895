@import "../base/base";

.auth-holder {
  border-radius: 20px;
  border: 10px solid rgb(0 0 0 / 70%);
  background-color: $color-gray-light;
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 800px;
  }
}
